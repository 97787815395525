import $ from 'jquery'
import readmore from 'readmore-js'

class ReadMoreToggle {
    constructor(el, height, show = 'Read more', hide = 'Read less', parent = null) {
        this.elements = {
            base: $(el),
            parent: parent,
            show: show,
            hide: hide,
        }

        this.toggleReadMore(height);
    }

    toggleReadMore(height) {
        const parent = this.elements.parent
        this.elements.base.readmore({
            speed: 300,
            collapsedHeight: height,
            moreLink: `<button class="readmore">${this.elements.show}</button>`,
            lessLink: `<button class="readmore readmore--expanded"">${this.elements.hide}</button>`,
            blockProcessed: function(trigger, element, expanded) {
                window.dispatchEvent(new Event('resize'));
            },
            beforeToggle: function(trigger, element, expanded) {
                if(!parent) return
                    $(element).parents(parent).toggleClass('is-active');
                    $(element)[0].scrollTop = 0;
            },
            afterToggle: function(trigger, element, expanded) {
                window.dispatchEvent(new Event('resize'));
                $(element).parent().parent()[0].scrollIntoView({behaviour: 'smooth', block: 'start'});

            }
        });
    }

}

export default ReadMoreToggle