import ReadMoreToggle from './modules/ReadMore'
import PickerMonthsDisabled from './modules/forms/PickerMonthsDisabled';


(function($) {
    'use strict';

    $('.c-layout-builder .c-cta--readmore .c-cta__inner .c-cta__text').each(function () {
        new ReadMoreToggle(this, 110, 'Read more', 'Read less', '.c-cta__inner');
    })

    $('.js-close-sub-menu').on('click', function() {
        $('.menu__item.is-active').removeClass('is-active');
        $('body').removeClass('sub-menu-is-active');
    });

    $('.menu__item--has-children > a').on('click', function(e) {
        e.preventDefault();
        $('body').addClass('sub-menu-is-active');
        $(this).parent().addClass('is-active');

        $('.c-offcanvas--menu .c-offcanvas__inner').scrollTop(0);
    });

    var clampHeadings = [
        '.c-post__heading',
        '.c-cta--excerpt .c-cta__heading',
        '.c-layout-builder .c-cta__heading',
        'c-profitroom-offers .c-cta__heading',
        '.c-cta--offer .c-cta__text p'
    ];

    $.each(clampHeadings, function(index, element) {
        $(element).each(function(index, element) {
            $clamp(element, {clamp: 2});
        });
    });

    // Disable specific months for forms with specific variation
    $('.c-form--food-experiences').each(function () {
        let gravityFormID = $(this).find('form').attr('id').replace('gform_', '') // get the form ID
        let monthsDisabledArr = [0, 1, 2, 3, 9, 10, 11] // 0 = January => 11 = Decemeber

        new PickerMonthsDisabled(gravityFormID, monthsDisabledArr)
    });

    window.addEventListener('resize', () => {
        if (!$('.c-media--two-tile').length > 0) return;

        $('.c-media--two-tile').each(function() {
            var $tile = $(this),
            $thumbnail = $tile.find('.js-thumbnail.show:nth-of-type(2) .c-media-gallery-trigger__thumbnail-image'),
            imageHeight = $thumbnail.outerHeight(),
            imageWidth = $thumbnail.innerWidth();

            $tile[0].style.setProperty('--image-height', imageHeight);
            $tile[0].style.setProperty('--image-width', imageWidth);

            setTimeout(function() {
                var textHeight = $tile.find('.c-media__content .c-media__text').outerHeight();
                $tile[0].style.setProperty('--text-height', textHeight);
            }, 50);
        });
    });

    window.dispatchEvent(new Event('resize'));

})(jQuery);
