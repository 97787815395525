export default class PickerMonthsDisabled {
    constructor (gravityFormID, monthsDisabledArr = []) 
    {
        this.gravityFormID = gravityFormID
        this.monthsDisabledArr = monthsDisabledArr

        this.init()
    }

    init()
    {
        gform.addFilter( 'gform_datepicker_options_pre_init', (optionsObj, formId, fieldId) => {
            if(this.gravityFormID == formId) {
                optionsObj.minDate = 0;

                optionsObj.beforeShowDay = (date) => {
                    if (jQuery.inArray(date.getMonth(), this.monthsDisabledArr) > -1) {
                        return [false, ''];
                    }
                    return [true, ''];
                };
            }
        
            return optionsObj;
        })
    }
}